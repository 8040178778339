/**
 * Vuex Store:  
 *  State
 *  Mutations
 *  Actions
 *  Getters
 */

import { createStore } from 'vuex';
//import axios from 'axios';
import httpComms from "../http-common";

/**
const store = createStore({
    state() {
        return {
            info: []
        };
    },
    mutations: {
        setInfo(state, s) {
            state.info.push(s) ;
        }
    }
});

export default store;

 */

export default createStore({
    state: {
        info: null,
    },
    mutations: {
        setInfo(state, s) {
            //console.log('Inside setInfo: ' + s);
            state.info = s ;
        }
    } ,
    actions: {
        checkApi({ commit }) {
            //console.log('Inside checkApi()');
            
        // httpComms.get('greetings')
        //     .then((result) => this.$store.commit('setInfo',result.data))
        //     .catch(console.error);
            // httpComms.get('greetings')
            // .then((result) => console.log('result: ' + result.data));
            // .catch(console.error);

            httpComms.get('greetings')
                .then((result) => commit('setInfo',result.data))
                .catch(console.error);
        }
    },
    getters: {
        getInfo (state) {
            return state.info;
        }
    }
});