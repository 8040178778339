<template>
  <div class="container">

    <hr class="my-5" />

    <!-- search -->
    <div class="input-group">
      <div class="form-outline">
        <input type="search" id="form1" class="form-control" />
        <label class="form-label" for="form1">Search</label>
      </div>
      <button type="button" class="btn btn-primary">
        <i class="fas fa-search"></i>
      </button>
     
    </div>

    <!-- /search -->

    <!-- search feedback -->
    <div class="container py-5">
      <div class="container">
        <h6>
        Search results: [ {{ availableInfo }} ]
      </h6>
      </div>
      
    </div>
    <!-- /search feedback -->

    <!-- pagination -->
    <div class="container py-5">
      <PaginationControl />
    </div> 
    <!-- /pagination -->

    <!-- Profiles List -->
    <div class="container py-5">
      <div class="container">
        <div class="hello">
          <ul class="list-group list-group-light">
            <li class="list-group-item">
              <ProfilePreview />
            </li>
            <li class="list-group-item">
              <ProfilePreview />
            </li>
            <li class="list-group-item">
              <ProfilePreview />
            </li>
            <li class="list-group-item">
              <ProfilePreview />
            </li>
          </ul>
        </div>
      </div>
      
    </div>

    <!-- /Profile List -->

    <!-- pagination -->
    <div class="container py-5">
      <PaginationControl />
    </div> 
    <!-- /pagination -->

    <hr class="my-5" />

  </div>

</template>
  


<script>
import ProfilePreview from './ProfilePreview.vue'
import PaginationControl from './PaginationControl.vue';

export default {
  components: {
    
    ProfilePreview,
    PaginationControl

  },
  
  data() {
    return {
      name: 'ProfilesPage',
      msg: 'Testing backend ...'

    }
  },
  computed: {
    availableInfo() {
      return this.$store.state.info;
    }
  },
  methods: {
    registerInfo() {
      const s = 'xxx';
      this.$store.commit('setInfo', { s });

      //debug.console.log('info :' + this.info());

      //
      //this.checkApi();
    }
  },
  // actions: {
  //   checkApi() {
  //     axios.get('greetings')
  //       .then((result) => this.$store.commit('setInfo',result.data))
  //   }
  // },
  // beforeMount() {
  //   this.registerInfo();
  // }
  created() {
    //this.$store.dispatch('checkApi');
  },
  beforeMount() {
    this.$store.dispatch('checkApi');
  },
  mounted() {
    //this.$store.dispatch('checkApi');
  }

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #2d1855;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4 {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: #bbb;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-4 {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-4::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: #bbb;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-4 {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right-4 {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid rgba(37, 117, 252, 1);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgba(37, 117, 252, 1);
}

/* Add arrows to the right container (pointing left) */
.right-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid rgba(245, 87, 108, 1);
  border-width: 10px 10px 10px 0;
  border-color: transparent rgba(245, 87, 108, 1) transparent transparent;
}

.img-small {
    max-width: 50%
}

/* Fix the circle for containers on the right side */
.right-4::after {
  left: -14px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .main-timeline-4::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-4 {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-4::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left-4::after,
  .right-4::after {
    left: 18px;
  }

  .left-4::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .right-4 {
    left: 0%;
  }
}

.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );
}

.gradient-custom-4 {
  /* fallback for old browsers */
  background: #f093fb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to left,
    rgba(240, 147, 251, 1),
    rgba(245, 87, 108, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to left,
    rgba(240, 147, 251, 1),
    rgba(245, 87, 108, 1)
  );
}
</style>