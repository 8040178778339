import { 
    createRouter, 
    createWebHashHistory 
} from "vue-router";

import HomePage from '../components/HomePage';
import ProfilesPage from '../components/Profiles/ProfilesPage';
import ProfileDetailsPage from '../components/Profiles/ProfileDetailsPage';


export default createRouter({
    history: createWebHashHistory(),
    routes: [{
        path: '/',
        name: 'Home',
        component: HomePage,
    },{
        path: '/profiles',
        name: 'Profiles',
        component: ProfilesPage,
    },{
        path: '/profiledetails',
        name: 'ProfileDetails',
        component: ProfileDetailsPage,
    }],
});