<template>
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-lg-10 mb-4 mb-lg-0">
            <div class="card mb-3" style="border-radius: .5rem;">
              <div class="row g-0">
                <div class="col-md-4 gradient-custom text-center text-white"
                  style="border-top-left-radius: .5rem; border-bottom-left-radius: .5rem;">
                  <router-link class="nav-link" to="/profiledetails">
                  <!-- <img src="https://cdn.simplexitydynamics.com/images/ace/profile-anne-v5.png"
                    alt="Avatar" class="img-fluid my-5" style="width: 80px;" /> -->
                    <img src="https://cdn.simplexitydynamics.com/images/ace/profile-ysa-v1.1.png"
                    alt="Avatar" class="rounded-3 img-fluid my-5" style="width: 150px;" />
                  </router-link>  
                  <h5><router-link class="nav-link" to="/profiledetails">Blaze</router-link></h5>
                  <p>Mage, Pathfinder</p>
                  <i class="fa-solid fa-star-of-david mb-5"></i>
                </div>
                <div class="col-md-8">
                  <div class="card-body p-4">
                    <h6>Personal Information</h6>
                    <hr class="mt-0 mb-4">
                    <div class="row pt-1">
                      <div class="col-6 mb-3">
                        <h6>Full Name</h6>
                        <p class="text-muted">Enna Ysa</p>
                      </div>
                      <div class="col-6 mb-3">
                        <h6>Clan</h6>
                        <p class="text-muted">Star <i class="fa-solid fa-star-of-david mb-5"></i></p>
                      </div>
                    </div>
                    <!-- <h6>Projects</h6> -->
                    <!-- <hr class="mt-0 mb-4"> -->
                    <div class="row pt-1">
                      <div class="col-6 mb-3">
                        <h6>Birthplace</h6>
                        <p class="text-muted">Lower Hutt</p>
                      </div>
                      <div class="col-6 mb-3">
                        <h6>Birthdate</h6>
                        <p class="text-muted">12 July 23010</p>
                      </div>
                    </div>
                    <h6>Abilities</h6>
                    <hr class="mt-0 mb-4">
                    <div class="d-flex justify-content-center">
                      <a data-bs-toggle="tooltip" title="Fire"><i class="fa-solid fa-fire fa-lg me-3"></i></a>
                      <a data-bs-toggle="tooltip" title="Vision"><i class="fa-solid fa-eye fa-lg me-3"></i></a>
                      <a data-bs-toggle="tooltip" title="Magic"><i class="fa-solid fa-hat-wizard fa-lg"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>


<script>
export default {
  name: 'ProfilePreview',
  props: {
    msg: String
  }
}
</script>


<style scoped>
.gradient-custom {
/* fallback for old browsers */
/* background: #f6d365; */
background: #93EBE8;

/* Chrome 10-25, Safari 5.1-6 */
/* background: -webkit-linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1)); */
background: -webkit-linear-gradient(to right bottom, rgba(147, 235, 232, 1), rgba(48, 144, 192, 1));

/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
/* background: linear-gradient(to right bottom, rgba(246, 211, 101, 1), rgba(253, 160, 133, 1)) */
background: linear-gradient(to right bottom, rgba(147, 235, 232, 1), rgba(48, 144, 192, 1))
}
</style>

