<template>

<!--Main Navigation-->
<header>
  <!-- Navbar -->
  <!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <!-- Container wrapper -->
  <div class="container-fluid">
    <!-- Toggle button -->
    <button
      class="navbar-toggler"
      type="button"
      data-mdb-toggle="collapse"
      data-mdb-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>

    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Navbar brand -->
      
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item active">
            <a class="navbar-brand mt-2 mt-sm-0" href="https://www.huttstudio.com" target="_blank" rel="noreferrer noopener">
                <img
                  src="https://cdn.simplexitydynamics.com/icons/hutts/favicon-32x32.png"
                  height="32"
                  alt="Hutt Studio"
                  loading="lazy"
                />
              </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
             Academy of Combat and Evolutions
            </a>
          </li>
          
        </ul>
     </div>
    <!-- Collapsible wrapper -->



    <!-- Right elements -->
    <div class="d-flex align-items-center">
      <!-- Icon -->
      <span class="me-3">
        <router-link class="nav-link" to="/">
        <i class="fas fa-home"></i>
        Home
      </router-link> 
      </span>
      
      <span class="me-3">
        <router-link class="nav-link" to="/profiles">
          <i class="fa-solid fa-id-card fa-fade fa-xl"></i>
          ACE Profiles
        </router-link>
      </span>

      <span class="me-3">
        <div class="container-fluid">
            <ul class="navbar-nav">
              <!-- Avatar -->
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbarDropdownMenuLink"
                  role="button" data-mdb-toggle="dropdown" aria-expanded="false">
                  <img src="https://cdn.simplexitydynamics.com/images/ace/profile-ysa-v1.1.png" class="rounded-circle"
                    height="22" alt="Avatar" loading="lazy" />
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <a class="dropdown-item" href="#">My profile</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Settings</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Logout</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
      </span>


      
    </div>
    <!-- Right elements -->
  </div>
  <!-- Container wrapper -->
</nav>
<!-- Navbar -->
  <!-- Navbar -->

  <!-- Jumbotron -->
  <div class="p-5 text-center bg-light">
    <a href="https://www.huttstudio.com" target="_blank" rel="noreferrer noopener">
      <img src="https://cdn.simplexitydynamics.com/icons/hutts/chrome-192x192.png" class="img-fluid" /> 
    </a>
    <h1 class="mb-3"><a href="https://www.huttstudio.com" target="_blank" rel="noreferrer noopener">Hutt Studio</a></h1>
    <h4 class="mb-3">Something we 
      <br/>l<i class="fa-regular fa-heart fa-beat-fade" style="color: #b00721;"></i>ve 
      <i class="fa-solid fa-plus fa-2xs fa-flip" style="color: #85a5db;"></i> 
      awes<i class="fa-regular fa-face-laugh-beam fa-bounce" style="color: #2c60ba;"></i>me 
      <br/>in the works!</h4>
    
  </div>
  <!-- Jumbotron -->
</header>
  <!--Main Navigation-->





</template>

<script>
export default {
  name: 'NavTop',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #2d1855;
}
#intro {
  /* Margin to fix overlapping fixed navbar */
  margin-top: 58px;
}

@media (max-width: 991px) {
  #intro {
    /* Margin to fix overlapping fixed navbar */
    margin-top: 45px;
  }
}

</style>
